import React, {useEffect, useState} from "react";
import Dropdown from "../../../primer/Dropdown";
import { excelOptions } from "../../../../_data/settings/doc-types";
import { useDispatch, useSelector } from "react-redux";
import {checkPermission} from "../../../../_helpers/commonFunctions";
import {
    setExport, setTooltipExport, setValuesChecksExport, setValuesExport
} from "../../../../_reducers/settingsSlice";
import axios from "axios";
import {toast} from "react-toastify";
import Checkbox from "../../../common/CheckBox";
import ModalInstructions from "../../../primer/POPUP-COMPONENTS/Instructions";
import EditableTable from "../../../primer/Editable-Table";
import TourForSection from "./settings-export-joyride";
import TourForExports from "./settings-export-joyride2";
import TourForExportArea from "./settings-export-joyride3";
import {Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {banksDummyData} from "../../../../_data/dummy_banks";
import {setUpdateInnerTab} from "../../../../_reducers/activitySlice";

export const SettingsOfExport = () => {
    const SETTINGS = useSelector((state) => state.SETTINGS);
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const [loader, setLoader] = useState(false);
    const [t] = useTranslation("common");
    const permissionsData = JSON.parse(localStorage.getItem("permissions"));

    const exportSectionOptions = [
        { value: "Products", label: t("SettingsImportExport.sectionProducts") },
        //{ value: "Product Movements", label: "Product Movements" },
        { value: "Clients", label: t("SettingsImportExport.sectionClients") },
        { value: "Client Movements", label: t("SettingsImportExport.sectionClientMovements") },
        //{ value: "Asset Movements", label: "Asset Movements" },
        { value: "Sales", label: t("SettingsImportExport.sectionSales") },
        { value: "Client Payments", label: t("SettingsImportExport.sectionClientPayments") },
        { value: "POS", label: t("SettingsImportExport.sectionPOS") },
        { value: "Cash Registers", label: t("SettingsImportExport.sectionCashRegisters") },
        { value: "Bank Accounts", label: t("SettingsImportExport.sectionBankAccounts") },
       // { value: "Suppliers", label: t("SettingsImportExport.sectionSuppliers") },
        //{ value: "Buys", label: "Buys" },
        //{ value: "Supplier Payments", label: "Supplier Payments" },
       // { value: "Accounting Accounts", label: t("SettingsImportExport.sectionAccountingAccounts") },
        //{ value: "Accounting Records", label: "Accounting Records" },
        //{ value: "Checks", label: "Checks" },
    ];
    const fieldsToCheck = [
        "myDataIncExp",
        "myDataRowType",
        "myDataRowCategory",
        "myDataRowInvoiceType",
        "contactAcceptNotifications",
        "contactNotes",
        "contactEmail",
        "contactLandline",
        "contactPhone2",
        "contactPhone",
        "contactName",
        "installationCustomVat",
        "installationVatReason",
        "installationVatCategory",
        "installationPostalCode",
        "installationCity",
        "installationDOY",
        "installationAddress",
        "installationNo",
        "chequeFirstDate",
        "chequeLastDate",
        "myDataUID",
        "myDataMark",
        "extraDataTaxType",
        "extraDataTaxCode",
        "extraDataTaxAmount",
        "isTaxLine",
        "productExtraTaxType",
        "productExtraTaxCode",
        "productExtraTaxAmount",
        "deliveryLocationAddress",
        "deliveryLocationCity",
        "deliveryLocationCountry",
        "deliveryLocationPostalCode"
    ];
    const dispatch = useDispatch();
    const handleOnChange = (e, type = "default", ddname = "", index) => {
        //let name = "";
        let value = "";
        if(type === "dd") {
            //name = ddname;
            value = e.value;
        } else {
            console.log(e.target.value);
            //name = e.target.name;
            value = e.target.value;
        }
        if (ddname === "section") {
            dispatch(setExport(value));
            loadTooltips();
        } else {
            if (type === "dd") {
                const isUnique = SETTINGS.export.fields.every(
                    (obj) => obj["column"] !== value
                );
                if (!isUnique) {
                    toast.error(t("SettingsImportExport.toasts.alreadySelectedColumn"));
                } else {
                    dispatch(setValuesExport({ type, value, index }));
                }
            } else if (type === "cb" || type === "checkAll") { // Checkbox
                if((ddname === "Delivery Location Address" || ddname === "Delivery Location City"
                        || ddname === "Delivery Location Country" || ddname === "Delivery Location Postal Code")
                    && SETTINGS.export.fields.filter((el) => el.fieldKey === "deliveryLocation")[0].checkBox === false
                    && SETTINGS.export.section === "Sales") {
                    toast.error(t("SettingsImportExport.toasts.deliveryLocationCheck"));
                } else if (ddname === "My Data Auth Code"
                    && SETTINGS.export.fields.filter((el) => el.fieldKey === "myDataMark")[0].checkBox === false
                    && SETTINGS.export.section === "Sales") {
                    toast.error(t("SettingsImportExport.toasts.myDataCheck"));
                } else {
                    dispatch(setValuesExport({type, value, index}));
                }
            }
        }
    }
    const exportTable = [
        {
            name: t("SettingsImportExport.no"),
            field: "no",
            editable: false,
            inputType: {
                type: "text",
            },
        },
        {
            name: t("SettingsImportExport.fieldName"),
            field: "fieldName",
            editable: false,
            inputType: {
                type: "text",
            },
        },
        {
            name: t("SettingsImportExport.excelColumn"),
            field: "column",
            editable: false,
            inputType: {
                type: "text",
            },
        },
    ];

    useEffect(() => {
        loadTooltips();
    }, [SETTINGS.export.section])

    const startExport = () => {
        setLoader(true);
        let bug = false;
        let count = 0;
        SETTINGS.export.fields.forEach((element) => {
            if (element.checkBox) {
                count++;
            }
        })
        if(count > 0) {
            SETTINGS.export.fields.forEach((element) => {
                if (element.checkBox) {
                    if (element.column === null) {
                        toast.error(t("SettingsImportExport.toasts.pleaseSelectColumnFor") + " "
                            + t("SettingsImportExport." + SETTINGS.export.section + "." + element["fieldKey"]));
                        bug = true;
                    }
                }
            });
        } else {
            bug = true;
            toast.error("Please select at least one column for export.");
        }
        setLoader(false);

        if(!bug) dispatch(setUpdateInnerTab(1));
    }
    const loadTooltips = () => {
        if(SETTINGS.export.section === "Products") {
            axios.post(process.env.REACT_APP_API_URL2 + `/import/productsToolTipCategories`, {company: company.id}, {
                headers: { "Content-Type": "application/json" },
            }).then((response) => {
                dispatch(setTooltipExport({key: "category", title: "Κατηγορίες Ειδών", content: response.data.message}));
            }).catch((error) => {
                console.log(error);
            });
        } else if(SETTINGS.export.section === "Client Payments") {
            axios.post(process.env.REACT_APP_API_URL2 + `/import/clientPaymentsToolTipPaymentTypes`, {company: company.id}, {
                headers: { "Content-Type": "application/json" },
            }).then((response) => {
                dispatch(setTooltipExport({key: "paymentType", title: "Τρόποι Πληρωμής", content: response.data.message}));
            }).catch((error) => {
                console.log(error);
            });
            axios.post(process.env.REACT_APP_API_URL2 + `/import/clientPaymentsToolTipInstallations`, {company: company.id}, {
                headers: { "Content-Type": "application/json" },
            }).then((response) => {
                dispatch(setTooltipExport({key: "installation", title: "Εγκαταστάσεις", content: response.data.message}));
            }).catch((error) => {
                console.log(error);
            });
            axios.post(process.env.REACT_APP_API_URL2 + `/import/clientPaymentsToolTipDocumentTypes`, {company: company.id}, {
                headers: {"Content-Type": "application/json"},
            }).then((response) => {
                dispatch(setTooltipExport({key: "documentTypeName", title: "Τύποι Παραστατικών Εισπράξεων", content: response.data.message}));
            }).catch((error) => {
                console.log(error);
            });
        } else if (SETTINGS.export.section === "Client Movements") {
            axios.post(process.env.REACT_APP_API_URL2 + `/import/clientPaymentsToolTipInstallations`, {company: company.id}, {
                headers: { "Content-Type": "application/json" },
            }).then((response) => {
                dispatch(setTooltipExport({key: "installationMasterID", title: "Εγκαταστάσεις", content : response.data.message}))
            }).catch((error) => {
                console.log(error);
            });
            axios.post(process.env.REACT_APP_API_URL2 + `/import/clientMovementsToolTipDocumentTypes`, {company: company.id}, {
                headers: {"Content-Type": "application/json"},
            }).then((response) => {
                dispatch(setTooltipExport({key: "documentTypeName", title: "Τύποι Παραστατικών Κινήσεων", content: response.data.message}));
            }).catch((error) => {
                console.log(error);
            });
        } else if (SETTINGS.export.section === "Sales") {
            axios.post(process.env.REACT_APP_API_URL2 + `/import/clientPaymentsToolTipInstallations`, {company: company.id}, {
                headers: { "Content-Type": "application/json" },
            }).then((response) => {
                dispatch(setTooltipExport({key: "installationMasterID", title: "Εγκαταστάσεις", content : response.data.message}))
            }).catch((error) => {
                console.log(error);
            });
            axios.post(process.env.REACT_APP_API_URL2 + `/import/salesToolTipDocumentTypes`, {company: company.id}, {
                headers: {"Content-Type": "application/json"},
            }).then((response) => {
                dispatch(setTooltipExport({key: "documentTypeName", title: "Τύποι Παραστατικών Πωλήσεων", content: response.data.message}));
            }).catch((error) => {
                console.log(error);
            });
            axios.post(process.env.REACT_APP_API_URL2 + `/import/clientPaymentsToolTipPaymentTypes`, {company: company.id}, {
                headers: { "Content-Type": "application/json" },
            }).then((response) => {
                dispatch(setTooltipExport({key: "paymentType", title: "Τρόποι Πληρωμής", content: "Πρέπει να εκχωρήσετε τον αριθμό<br/><br/>" + response.data.message}));
            }).catch((error) => {
                console.log(error);
            });
            axios.post(process.env.REACT_APP_API_URL2 + `/import/salesToolTipCarNumbers`, {company: company.id}, {
                headers: { "Content-Type": "application/json" },
            }).then((response) => {
                dispatch(setTooltipExport({key: "carNumber", title: "Αριθμοί αυτοκινήτων", content: response.data.message}));
            }).catch((error) => {
                console.log(error);
            });
            axios.post(process.env.REACT_APP_API_URL2 + `/import/salesToolTipSendingGoods`, {company: company.id}, {
                headers: { "Content-Type": "application/json" },
            }).then((response) => {
                dispatch(setTooltipExport({key: "wayOfSendingGoods", title: "Τρόποι αποστολής εμπορευμάτων", content: response.data.message}));
            }).catch((error) => {
                console.log(error);
            });
        } else if (SETTINGS.export.section === "Bank Accounts") {
            let prepContent = "<table className='table-bordered' style='text-align: center; margin-right: auto; margin-left: auto;'><tr><th>Swift</th><th>Όνομα Τράπεζας</th></tr>";
            for(let bank in banksDummyData) {
                prepContent += "<tr>";
                prepContent += "<td>" + banksDummyData[bank].swift + "</td>"
                prepContent += "<td>" + banksDummyData[bank].name + "</td>"
                prepContent += "</tr>";
            }
            prepContent += "</table>";
            dispatch(setTooltipExport({key: "bankSwift", title: "Swift Τραπεζών", content: prepContent}))
        } else if (SETTINGS.export.section === "Accounting Accounts") {
            axios.post(process.env.REACT_APP_API_URL2 + "/import/accountingAccountsSettings", {company: company.id}, {
                headers: {"Content-Type": "application/json"}
            }).then((response) => {
                dispatch(setTooltipExport({key: "category", title: "Πρότυπα Λογαριασμών", content: response.data.message}));
            }).catch((error) => {
                console.log(error);
            })
        }
    }
    return (
        <>
            <div className="export-settings">
                {!SETTINGS.export.section && <TourForSection />}
                <div className="row">
                    <div className="col-12 col-md-4">
                        <Dropdown
                            label={t("SettingsImportExport.section")}
                            options={exportSectionOptions}
                            value={exportSectionOptions.filter((el) => el.value === SETTINGS.export.section)}
                            classes="sectionDropdown"
                            name="section"
                            enabledValue={true}
                            onChange={(e) => handleOnChange(e, "dd", "section")}
                        />
                    </div>
                </div>
                {SETTINGS.export.section !== "" && (
                    <>
                        <hr />
                        <TourForExports />
                        <div className="row">
                            <div className="col-12 col-md-3">
                                <label className="w-100">
                                    <strong>{t("SettingsImportExport.fieldName")}</strong>
                                </label>
                            </div>
                            <div className="col-12 col-md-3">
                                <label className="w-100">
                                    <strong>{t("SettingsImportExport.excelColumn")}</strong>
                                </label>
                            </div>
                            <div className="col-12 col-md-3">
                                <label className="w-100">
                                    <strong>{t("SettingsImportExport.fieldName")}</strong>
                                </label>
                            </div>
                            <div className="col-12 col-md-3">
                                <label className="w-100">
                                    <strong>{t("SettingsImportExport.excelColumn")}</strong>
                                </label>
                            </div>
                            <div className="row">
                                {SETTINGS.export.fields.map((field, i) => {
                                    return (
                                        <>
                                            {SETTINGS.export.section === "Sales" && field.fieldKey === "documentTypeName" &&
                                                <>
                                                    <div className="text-muted">{t("SettingsImportExport.Sales.mutedBasicData")}</div>
                                                </>
                                            }
                                            {SETTINGS.export.section === "Sales" && field.fieldKey === "productName" &&
                                                <>
                                                    <hr/>
                                                    <div className="text-muted">{t("SettingsImportExport.Sales.mutedBasicDataLine")}</div>
                                                </>
                                            }
                                            {SETTINGS.export.section === "Sales" && field.fieldKey === "myDataUID" &&
                                                <>
                                                    <hr/>
                                                    <div className="text-muted">{t("SettingsImportExport.Sales.mutedMyData")}</div>
                                                </>
                                            }
                                            {SETTINGS.export.section === "Sales" && field.fieldKey === "extraDataTaxType" &&
                                                <>
                                                    <hr/>
                                                    <div className="text-muted">{t("SettingsImportExport.Sales.mutedExtraData")}</div>
                                                </>
                                            }
                                            {SETTINGS.export.section === "Accounting Accounts" && field.fieldKey === "fee" &&
                                                <>
                                                    <hr/>
                                                    <div className="text-muted">{t("SettingsImportExport.Accounting Accounts.mutedCaseTax")}</div>
                                                </>
                                            }
                                            <div className="col-12 col-md-3 forJoyReqField">
                                                <Checkbox
                                                    text={t("SettingsImportExport." + SETTINGS.export.section + "." + field["fieldKey"])}
                                                    //required={field["required"]}
                                                    checked={field["checkBox"]}
                                                    defaultValue={field["checkBox"]}
                                                    value={field["checkBox"]}
                                                    //disabled={field["disabled"]}
                                                    className="mb-1 mr-2 centered-checkbox"
                                                    onChange={(e) => {
                                                        if(fieldsToCheck.includes(field["fieldKey"])) {
                                                            handleOnChange(e, "checkAll", field["fieldName"], i);
                                                        } else {
                                                            handleOnChange(e, "cb", field["fieldName"], i);
                                                        }
                                                    }
                                                    }
                                                />
                                            </div>
                                            <div className="col-12 col-md-3">
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                    }}
                                                >
                                                    <div className="col-md-8 forJoyReqColumn">
                                                        <Dropdown
                                                            options={excelOptions.filter((el) => SETTINGS.export.fields.every((obj) => obj.column !== el.value))}
                                                            disabled={!field["checkBox"]}
                                                            value={excelOptions.find((option) => {
                                                                return option.value === field["column"];
                                                            })}
                                                            onChange={(e) =>
                                                                handleOnChange(e, "dd", field["fieldName"], i)
                                                            }
                                                            enabledValue={true}
                                                        />
                                                    </div>
                                                    <div className="col-md-4">
                                                        {field["tooltip"] && (
                                                            <div className="forJoyTooltip" style={{width: '35%'}}>
                                                                <ModalInstructions data={field["tooltip"]} />
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    );
                                })}
                            </div>
                        </div>
                    </>
                )}
                <hr />
                <div className="row">
                    {SETTINGS.export.section !== "" && checkPermission("settings-importexport/export-read", permissionsData) && (
                        <div className="col-12 ml-auto ">
                            <button
                                type="button"
                                className="btn btn-primary float-right"
                                onClick={(e) => {
                                    startExport();
                                }}
                                >
                                {t("SettingsImportExport.startExport")}
                                <span> </span>
                                {loader && (
                                    <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                    ></span>
                                )}
                            </button>
                        </div>
                    )}
                    <p>{t("SettingsImportExport.results")}</p>
                    <EditableTable
                        columns={exportTable}
                        data={SETTINGS.export.result}
                    />
                </div>
            </div>
        </>
    );
};
export const SettingsExport = () => {
    const SETTINGS = useSelector((state) => state.SETTINGS);
    const company = useSelector((state) => state.COMPANY_DATA.company);
    const dispatch = useDispatch();
    const [response, setResponse] = useState(null);
    const permissionsData = JSON.parse(localStorage.getItem("permissions"));
    const [t] = useTranslation("common");

    useEffect(() => {
        if (!SETTINGS.export.section) {
            toast.error(t("SettingsImportExport.toasts.selectionEmpty"));
            dispatch(setUpdateInnerTab(0));
        }
        if (SETTINGS.export.section) {
            let willReturn = false;
            SETTINGS.export.fields.forEach((element) => {
                if (element.checkBox) {
                    if (element.column === null) {
                        willReturn = true;
                    }
                }
            });
            if(willReturn) {
                toast.error(t("SettingsImportExport.toasts.someExcelColumnsAreEmpty"));
                dispatch(setUpdateInnerTab(0));
            }
        }
    }, [])

    const backtoPrevious = () => {
        dispatch(setUpdateInnerTab(0));
    }
    const exportButtonClicked = () => {
        const filteredArray = SETTINGS.export.fields.filter(
            (obj) => obj.checkBox
        );
        const cMap = filteredArray.reduce((acc, cur) => {
            acc[cur.fieldKey] = cur.column.toString();
            return acc;
        }, {});


        let endpoint = "";
        if (SETTINGS.export.section === "Products") {
            endpoint = "products";
        } else if (SETTINGS.export.section === "Clients") {
            endpoint = "clients";
        } else if (SETTINGS.export.section === "Client Payments") {
            endpoint = "clientPayments";
        } else if (SETTINGS.export.section === "Client Movements") {
            endpoint = "clientMovements";
        } else if (SETTINGS.export.section === "Sales") {
            endpoint = "sales";
        } else if (SETTINGS.export.section === "POS") {
            endpoint = "pos";
        } else if (SETTINGS.export.section === "Cash Registers") {
            endpoint = "cashRegisters";
        } else if (SETTINGS.export.section === "Bank Accounts") {
            endpoint = "bankAccounts";
        } else if (SETTINGS.export.section === "Suppliers") {
            endpoint = "suppliers";
        } else if (SETTINGS.export.section === "Accounting Accounts") {
            endpoint = "accountingAccounts";
        }
        axios.post(process.env.REACT_APP_API_URL2 + `/export/${endpoint}Check`, {company: company.id}, {
            headers: { "Content-Type": "application/json" },
        }).then((response) => {
            setResponse(response);
        }).catch((error) => {
            toast.error(error);
            console.log(error);
        });
        axios.post(process.env.REACT_APP_API_URL2 + `/export/${endpoint}`, {company: company.id, columnMapping: JSON.stringify(cMap), textInstead: SETTINGS.export.textInstead}, {
            headers: { "Content-Type": "application/json" },
            responseType: 'blob',
        }).then((response) => {
            let url = window.URL.createObjectURL(new Blob([response.data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}));
            let a = document.createElement('a');
            a.href = url;
            a.download = `${SETTINGS.export.section}.xlsx`;
            document.body.appendChild(a);
            a.click();
            a.remove();
        }).catch((error) => {
            toast.error(error);
            console.log(error);
        });
    }
    const handleOnChange = (e, type = "default", tname) => {
        let name = "";
        let value = "";

        if(type === "cb") {
            if(tname === "textInstead") {
                value = !SETTINGS.export.textInstead;
                name = e.target.name;
            }
            name = tname;
            dispatch(setValuesChecksExport({ type, name, value }));
        }
    }

    return (
        <>
            <TourForExportArea />
            <Row>
                <div className="col-md-3">
                    <Checkbox
                        text={t("SettingsImportExport.textInstead")}
                        value={SETTINGS.export.textInstead}
                        defaultValue={SETTINGS.export.textInstead}
                        className="mb-1 mr-2 centered-checkbox forJoyCB"
                        onChange={(e) => handleOnChange(e, "cb", "textInstead")}
                    />
                </div>
            </Row>
            <p className="text-danger">{t("SettingsImportExport.textOnlyDanger")}</p>
            <button type="button" className="btn btn-primary mr-3 mb-3 forJoyBack" onClick={(e) => {backtoPrevious();}}>{t("SettingsImportExport.back")}</button>
            {checkPermission("settings-importexport/export-read", permissionsData) &&
                <button type="button" className="btn btn-primary mb-3 forJoyExport" onClick={(e) => {exportButtonClicked();}}>{t("SettingsImportExport.export")}</button>
            }
            <div className="row">
                <div className="mb-2 col-12">
                    <p>Console</p>
                    <div className="bg-light w-100 border p-3">
                        {response && response.data.status === "200" &&
                            <p className="text-success">{response.data.count} {response.data.recordName} exported successfully. Sending XLSX...</p>
                        }
                        {response && response.data.status !== "200" &&
                            <p className="text-danger">Connection refused.</p>
                        }
                    </div>
                </div>
            </div>
        </>
    );
};
